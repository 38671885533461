import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import { ContentDashboardBlockAdminDropdownFragment$key } from "@/dashboard/blocks/kinds/__generated__/ContentDashboardBlockAdminDropdownFragment.graphql"
import usePermissions from "@utils/hook/usePermissions"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: ContentDashboardBlockAdminDropdownFragment$key
  className?: string
}

function ContentDashboardBlockAdminDropdown({ dashboardBlockKey, className }: Props) {
  const activeOrganization = useActiveOrganization()!
  const organizationPermissions = usePermissions(activeOrganization)
  const canManageContentDashboardBlock = organizationPermissions.has("content.manage")

  const dashboardBlock = useFragment<ContentDashboardBlockAdminDropdownFragment$key>(
    graphql`
      fragment ContentDashboardBlockAdminDropdownFragment on ContentDashboardBlock {
        ...DashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  if (!canManageContentDashboardBlock) return null

  return (
    <DashboardBlockAdminDropdown
      className={className}
      dashboardBlockKey={dashboardBlock}
    />
  )
}

export default ContentDashboardBlockAdminDropdown
