import CollectionFolderDashboardBlockContent from "@/dashboard/blocks/kinds/CollectionFolderDashboardBlockContent"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { CollectionFolderDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockFragment.graphql"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: CollectionFolderDashboardBlockFragment$key
  index?: number
}

function CollectionFolderDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props
  const block = useFragment<CollectionFolderDashboardBlockFragment$key>(
    graphql`
      fragment CollectionFolderDashboardBlockFragment on CollectionFolderDashboardBlock {
        content {
          id
        }
        ...DashboardBlockItemTemplateFragment
        ...CollectionFolderDashboardBlockContentFragment
      }
    `,
    dashboardBlockKey
  )

  if (!block.content) {
    return null
  }

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <CollectionFolderDashboardBlockContent dashboardBlockKey={block} />
    </DashboardBlockItemTemplate>
  )
}

export default CollectionFolderDashboardBlock
