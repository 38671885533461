import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import ContentCard from "@/content/detail/ContentCard"
import ContentTitleSection from "@/content/detail/sections/ContentTitleSection"
import ContentDashboardBlockAdminDropdown from "@/dashboard/blocks/kinds/ContentDashboardBlockAdminDropdown"
import { ContentDashboardBlockFullContentFragment$key } from "@/dashboard/blocks/kinds/__generated__/ContentDashboardBlockFullContentFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: ContentDashboardBlockFullContentFragment$key
}

export default function ContentDashboardBlockFullContent({ dashboardBlockKey }: Props) {
  const block = useFragment<ContentDashboardBlockFullContentFragment$key>(
    graphql`
      fragment ContentDashboardBlockFullContentFragment on ContentDashboardBlock {
        contentUsages {
          edges {
            node {
              id
              ...ContentCard_ContentUsageFragment
              content {
                id
                ...ContentCard_ContentFragment
                ...ContentTitleSectionFragment
              }
            }
          }
        }
        ...ContentDashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  const contentUsageDrawer = useContentUsageDrawer()
  const classes = useStyles()
  const contentUsage = Relay.connectionToArray(block.contentUsages)[0]

  return (
    <ContentCard
      testid={`ContentDashboardBlockFullContent`}
      contentKey={contentUsage!.content}
      contentUsageKey={contentUsage!}
      onClick={openContentUsageDrawer}
      classes={{ footerContainer: classes.footerContainer }}
      className={classes.card}
      hideHeader
      titleContent={
        <div className={classes.titleContainer}>
          <ContentTitleSection
            contentKey={contentUsage!.content}
            testid={"ContentDashboardBlockFullContent"}
            onGoToDetailPage={openContentUsageDrawer}
          />

          <ContentDashboardBlockAdminDropdown
            className={classes.dashboardBlockAdminDropdown}
            dashboardBlockKey={block}
          />
        </div>
      }
    />
  )

  function openContentUsageDrawer() {
    contentUsageDrawer.open({ drawerContentUsageId: contentUsage!.id })
  }
}

const useStyles = makeUseStyles((theme) => ({
  card: {
    boxShadow: theme.palette.groovyDepths.xs,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  dashboardBlockAdminDropdown: {
    marginLeft: "auto",
  },
  footerContainer: {
    marginTop: "auto",
  },
}))
