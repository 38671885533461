import CreateContentModuleButton from "@/content-usage/modules/actions/CreateContentModuleButton"
import GenerateCurriculumWithAIButton from "@/content/ai/button/GenerateCurriculumWithAIButton"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import AddWithCircleIcon from "@/core/ui/iconsax/linear/add-circle.svg"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import { DiscoEmptyState } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { useEffect, useState } from "react"
import { generatePath, useHistory } from "react-router-dom"

type Props = {
  curriculumId: GlobalID
  refetchCurriculum?: () => void
}

export default function CurriculumDashboardBlockEmptyState({
  curriculumId,
  refetchCurriculum,
}: Props) {
  const { curriculumGeneration } = useFeatureFlags()
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const history = useHistory()

  const { status: aiGenerationStatus, refetchStatus } = useTrackAIGenerationStatus({
    entityId: activeProduct?.curriculum?.id || "",
    skip: !curriculumGeneration,
  })

  const [isAIGenerationRunning, setIsAIGenerationRunning] = useState(
    aiGenerationStatus === "running" || aiGenerationStatus === "pending"
  )
  const [hasStartedGeneration, setHasStartedGeneration] = useState(isAIGenerationRunning)

  useEffect(() => {
    // If there is an on-going AI generation, show the status popup
    if (aiGenerationStatus === "running" || aiGenerationStatus === "pending") {
      setIsAIGenerationRunning(true)
    }

    // Refetch the curriculum when the AI generation is completed in the background
    if (!hasStartedGeneration) return
    if (aiGenerationStatus === "completed" && curriculumGeneration) {
      refetchCurriculum?.()
      setHasStartedGeneration(false)
    }
  }, [
    refetchCurriculum,
    aiGenerationStatus,
    curriculumGeneration,
    setHasStartedGeneration,
    hasStartedGeneration,
  ])

  return (
    <CreateContentModuleButton
      parentEntity={"curriculum"}
      parentEntityId={curriculumId}
      disabled={isAIGenerationRunning}
    >
      {(buttonProps) => {
        return (
          <DiscoEmptyState
            className={classes.emptyState}
            testid={"CurriculumDashboardBlock"}
            icon={!isAIGenerationRunning && <AddWithCircleIcon width={40} height={40} />}
            title={
              isAIGenerationRunning ? "Generating Curriculum with AI" : "No Modules yet"
            }
            subtitle={
              isAIGenerationRunning
                ? "Using AI to create your custom curriculum with modules lessons, assignments, and quizzes. This may take a few minutes..."
                : "Build your Curriculum with modules that contain videos, lessons, assignments, and quizzes."
            }
            onClick={buttonProps.onClick as VoidFunction}
            variant={"compact"}
            buttons={
              curriculumGeneration && (
                <GenerateCurriculumWithAIButton
                  curriculumId={curriculumId}
                  onSuccess={handleSuccessfulGeneration}
                >
                  {(btnProps) => (
                    <AIButton
                      {...btnProps}
                      onClick={(e) => {
                        e.stopPropagation()
                        btnProps.onClick(e)
                      }}
                      leftIcon={"stars"}
                    >
                      {isAIGenerationRunning
                        ? "Generating Curriculum"
                        : "Generate Curriculum with AI"}
                    </AIButton>
                  )}
                </GenerateCurriculumWithAIButton>
              )
            }
          />
        )
      }}
    </CreateContentModuleButton>
  )

  function handleSuccessfulGeneration() {
    if (!activeProduct) return
    if (!hasStartedGeneration) setHasStartedGeneration(true)

    // Refetch the AI generation status
    refetchStatus()

    // Redirect to the curriculum overview page
    history.push(
      generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
        productSlug: activeProduct.slug,
      })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    marginBottom: theme.spacing(1.5),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))
