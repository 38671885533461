import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import AddWithCircleIcon from "@/core/ui/iconsax/linear/add-circle.svg"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { EventsDashboardBlockCardViewFragment$key } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockCardViewFragment.graphql"
import { EventsDashboardBlockCardViewOrganizationPaginationQuery } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockCardViewOrganizationPaginationQuery.graphql"
import { EventsDashboardBlockCardViewProductPaginationQuery } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockCardViewProductPaginationQuery.graphql"
import { EventsDashboardBlockCardViewQuery } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockCardViewQuery.graphql"
import { EventsDashboardBlockCardView_OrganizationPaginationFragment$key } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockCardView_OrganizationPaginationFragment.graphql"
import { EventsDashboardBlockCardView_ProductPaginationFragment$key } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockCardView_ProductPaginationFragment.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import CreateEventButton from "@/organization/occurrence/create-form/CreateEventButton"
import OccurrenceListItem, {
  OccurrenceListItemSkeleton,
} from "@/product/course/event/list/item/OccurrenceListItem"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoCarousel,
  DiscoCarouselSkeleton,
  DiscoEmptyState,
  DiscoIcon,
  DiscoIconButton,
} from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"

interface Props {
  dashboardBlockKey: EventsDashboardBlockCardViewFragment$key
  index?: number
}

function EventsDashboardBlockCardView({ dashboardBlockKey, index }: Props) {
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const labels = useLabels()

  const block = useFragment<EventsDashboardBlockCardViewFragment$key>(
    graphql`
      fragment EventsDashboardBlockCardViewFragment on UpcomingEventsDashboardBlock {
        productId
        organizationId
        showCover
        position
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const { sliceSize, ...carouselProps } = useDashboardBlockCarouselSize(3, block, "left")

  const { organization, product } = useLazyLoadQuery<EventsDashboardBlockCardViewQuery>(
    graphql`
      query EventsDashboardBlockCardViewQuery(
        $organizationId: ID!
        $first: Int
        $productId: ID!
      ) {
        organization: node(id: $organizationId) {
          ... on Organization {
            ...EventsDashboardBlockCardView_OrganizationPaginationFragment
              @arguments(first: $first)
            ...usePermissionsFragment
          }
        }
        product: node(id: $productId) {
          ... on Product {
            slug
            ...EventsDashboardBlockCardView_ProductPaginationFragment
              @arguments(first: $first)
            ...usePermissionsFragment
          }
        }
      }
    `,
    {
      organizationId: block.productId ? "" : block.organizationId,
      productId: block.productId || "",
      first: sliceSize,
    },
    { fetchPolicy: "network-only" }
  )

  const {
    data: organizationPaginationData,
    hasNext: organizationHasNext,
    hasPrevious: organizationHasPrevious,
    loadNext: organizationLoadNext,
    isLoadingNext: organizationIsLoadingNext,
  } = usePaginationFragment<
    EventsDashboardBlockCardViewOrganizationPaginationQuery,
    EventsDashboardBlockCardView_OrganizationPaginationFragment$key
  >(
    graphql`
      fragment EventsDashboardBlockCardView_OrganizationPaginationFragment on Organization
      @refetchable(queryName: "EventsDashboardBlockCardViewOrganizationPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        occurrences(first: $first, after: $after, datetimeFilter: upcoming)
          @connection(key: "EventsDashboardBlockCardView__occurrences") {
          __id
          totalCount
          edges {
            node {
              id
              ...OccurrenceListItemFragment
            }
          }
        }
      }
    `,
    organization || null
  )

  const {
    data: productPaginationData,
    hasNext: productHasNext,
    hasPrevious: productHasPrevious,
    loadNext: productLoadNext,
    isLoadingNext: productIsLoadingNext,
    refetch,
  } = usePaginationFragment<
    EventsDashboardBlockCardViewProductPaginationQuery,
    EventsDashboardBlockCardView_ProductPaginationFragment$key
  >(
    graphql`
      fragment EventsDashboardBlockCardView_ProductPaginationFragment on Product
      @refetchable(queryName: "EventsDashboardBlockCardViewProductPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        eventOccurrences(first: $first, after: $after, isUpcoming: true)
          @connection(key: "EventsDashboardBlockCardView__eventOccurrences") {
          __id
          totalCount
          edges {
            node {
              id
              ...OccurrenceListItemFragment
            }
          }
        }
      }
    `,
    product || null
  )

  // Listen for invalidation in CreateEventForm
  useSubscribeToInvalidationState(
    productPaginationData?.eventOccurrences
      ? [productPaginationData?.eventOccurrences?.__id]
      : [],
    () => {
      refetch({
        first: sliceSize,
      })
    }
  )

  const occurrences = organizationPaginationData
    ? Relay.connectionToArray(organizationPaginationData.occurrences)
    : Relay.connectionToArray(productPaginationData?.eventOccurrences)
  const totalCount = organizationPaginationData
    ? organizationPaginationData.occurrences.totalCount
    : productPaginationData?.eventOccurrences?.totalCount || 0

  const permissions = usePermissions(product || organization)

  if (!permissions.has("events.create") && !occurrences.length) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoCarousel
        title={"Events"}
        isDashboardBlock
        moreActions={
          <>
            {permissions.has("events.create") && (
              <CreateEventButton>
                {(buttonProps) => {
                  return (
                    <DiscoIconButton
                      {...buttonProps}
                      tooltip={"Add Event"}
                      size={"small"}
                    >
                      <DiscoIcon icon={"add-circle"} />
                    </DiscoIconButton>
                  )
                }}
              </CreateEventButton>
            )}
            <DashboardBlockAdminDropdown dashboardBlockKey={block} />
          </>
        }
        data={occurrences}
        item={(item, idx) => (
          <OccurrenceListItem
            key={item.id}
            occurrenceKey={item}
            testid={`OccurrenceCard-${idx}`}
            hideCover={!block.showCover}
            variant={"card"}
          />
        )}
        itemSkeleton={<OccurrenceListItemSkeleton variant={"card"} />}
        totalCount={totalCount}
        refetch={{
          hasNext: block.productId ? productHasNext : organizationHasNext,
          hasPrevious: block.productId ? productHasPrevious : organizationHasPrevious,
          loadMore: () => {
            if (block.productId) {
              productLoadNext(sliceSize)
            } else {
              organizationLoadNext(sliceSize)
            }
          },
          isLoading: block.productId ? productIsLoadingNext : organizationIsLoadingNext,
        }}
        emptyState={
          activeProduct ? (
            <CreateEventButton>
              {(buttonProps) => (
                <DiscoEmptyState
                  className={classes.emptyState}
                  testid={"EventsDashboardBlockCardView"}
                  icon={<AddWithCircleIcon width={40} height={40} />}
                  title={"No Events"}
                  subtitle={"Create your first event now!"}
                  onClick={buttonProps.onClick as VoidFunction}
                  variant={"compact"}
                />
              )}
            </CreateEventButton>
          ) : (
            <DiscoEmptyState
              className={classes.emptyState}
              testid={"EventsDashboardBlockCardView"}
              title={"No Events"}
              subtitle={`Add an event to one of your ${labels.experience.plural} to see it here.`}
              variant={"compact"}
            />
          )
        }
        {...carouselProps}
      />
    </DashboardBlockItemTemplate>
  )
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    marginBottom: theme.spacing(1.5),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))

export function EventsDashboardBlockCardViewSkeleton() {
  return (
    <DiscoCarouselSkeleton
      showTitle
      item={<OccurrenceListItemSkeleton variant={"card"} />}
      slidesPerView={3}
    />
  )
}

export default Relay.withSkeleton({
  component: EventsDashboardBlockCardView,
  skeleton: EventsDashboardBlockCardViewSkeleton,
})
