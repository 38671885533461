import ContentModuleCard, {
  ContentModuleCardSkeleton,
} from "@/content-usage/modules/components/ContentModuleCard"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import CurriculumDashboardBlockEmptyState from "@/dashboard/blocks/kinds/CurriculumDashboardBlockEmptyState"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { CurriculumDashboardBlockCardViewFragment$key } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockCardViewFragment.graphql"
import { CurriculumDashboardBlockCardViewPaginationFragment$key } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockCardViewPaginationFragment.graphql"
import { CurriculumDashboardBlockCardViewPaginationQuery } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockCardViewPaginationQuery.graphql"
import { CurriculumDashboardBlockCardViewQuery } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockCardViewQuery.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import MemberCurriculumProgressBarWithQuery from "@/product/reports/page/curriculum/MemberCurriculumProgressBarWithQuery"
import Relay from "@/relay/relayUtils"
import { DiscoCarousel, DiscoCarouselSkeleton } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useFragment, usePaginationFragment } from "react-relay"

type Props = {
  dashboardBlockKey: CurriculumDashboardBlockCardViewFragment$key
  index?: number
}

function CurriculumDashboardBlockCardView({ dashboardBlockKey, index }: Props) {
  const block = useFragment<CurriculumDashboardBlockCardViewFragment$key>(
    graphql`
      fragment CurriculumDashboardBlockCardViewFragment on CurriculumDashboardBlock {
        productId
        organizationId
        position
        title
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const [{ product }, refetch] =
    Relay.useRefetchableQuery<CurriculumDashboardBlockCardViewQuery>(
      graphql`
        query CurriculumDashboardBlockCardViewQuery($id: ID!, $first: Int) {
          product: node(id: $id) {
            ... on Product {
              curriculum {
                id
                ...CurriculumDashboardBlockCardViewPaginationFragment
                  @arguments(first: $first)
              }
              ...usePermissionsFragment
            }
          }
        }
      `,
      {
        // We are fetching all modules in the curriculum here since we need the full list
        // in order to determine the index of the first incomplete module
        id: block.productId || "",
      },
      { fetchPolicy: "network-only" }
    )

  const { sliceSize, ...carouselProps } = useDashboardBlockCarouselSize(4, block)

  const { data, hasNext, hasPrevious, loadNext, isLoadingNext } = usePaginationFragment<
    CurriculumDashboardBlockCardViewPaginationQuery,
    CurriculumDashboardBlockCardViewPaginationFragment$key
  >(
    graphql`
      fragment CurriculumDashboardBlockCardViewPaginationFragment on Curriculum
      @refetchable(queryName: "CurriculumDashboardBlockCardViewPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        modules(first: $first, after: $after)
          @connection(key: "CurriculumDashboardBlockCardView__modules") {
          __id
          totalCount
          edges {
            node {
              id
              releasedAt
              viewerHasCompleted
              ...ContentModuleCardFragment
            }
          }
        }
      }
    `,
    product?.curriculum || null
  )

  const canManage = usePermissions(product).has("curriculum.manage")

  if (!data || !product?.curriculum) return null

  const modules = Relay.connectionToArray(data?.modules)
  const completedModules = modules.filter((m) => m.viewerHasCompleted)
  const incompleteModules = modules.filter((m) => !m.viewerHasCompleted)
  const sortedModules = canManage ? modules : [...completedModules, ...incompleteModules]

  if (!canManage && !modules.length) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoCarousel
        testid={"CurriculumDashboardBlock"}
        isDashboardBlock
        title={block.title}
        {...carouselProps}
        // Return the index of the first incomplete module
        startingIndex={sortedModules.findIndex((m) =>
          canManage ? true : !m.viewerHasCompleted
        )}
        moreActions={
          canManage ? (
            <DashboardBlockAdminDropdown dashboardBlockKey={block} />
          ) : (
            <MemberCurriculumProgressBarWithQuery labelVariant={"modules"} />
          )
        }
        data={sortedModules}
        item={(module, i) => (
          <ContentModuleCard
            key={module.id}
            testid={`ContentModuleCard.module-${i}`}
            contentUsageKey={module}
          />
        )}
        hideIndicator={!canManage}
        totalCount={data?.modules.totalCount}
        refetch={{
          hasNext,
          hasPrevious,
          loadMore: () => loadNext(sliceSize),
          isLoading: isLoadingNext,
        }}
        emptyState={
          canManage &&
          product.curriculum && (
            <CurriculumDashboardBlockEmptyState
              curriculumId={product.curriculum.id}
              refetchCurriculum={refetchCurriculum}
            />
          )
        }
      />
    </DashboardBlockItemTemplate>
  )

  function refetchCurriculum() {
    if (!block.productId) return
    refetch({ id: block.productId })
  }
}

function CurriculumDashboardBlockCardViewSkeleton() {
  return (
    <DiscoCarouselSkeleton
      item={<ContentModuleCardSkeleton />}
      showTitle
      slidesPerView={3}
    />
  )
}

export default Relay.withSkeleton({
  component: CurriculumDashboardBlockCardView,
  skeleton: CurriculumDashboardBlockCardViewSkeleton,
})
