import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  DiscoLink,
  DiscoLinkProps,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoContainerButton, {
  DiscoContainerButtonProps,
} from "@disco-ui/button/DiscoContainerButton"
import { Paper, useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/styles"
import classnames from "classnames"
import { ReactNode } from "react"

type ProductAdminStatisticsReportCardProps = Pick<DiscoLinkProps, "to"> &
  Pick<DiscoContainerButtonProps, "onClick"> & {
    value?: string | number | null
    subtitle: string
    tooltipText?: string
    icons?: ReactNode
    tags?: ReactNode
    testid?: string
    links?: ReactNode
    classes?: Partial<ClassNameMap<"root">>
  }

function ProductAdminStatisticsReportCard(props: ProductAdminStatisticsReportCardProps) {
  const {
    value,
    subtitle,
    tooltipText,
    icons,
    tags,
    testid = "ProductAdminStatisticsReportCard",
    classes: propClasses,
    to,
    onClick,
  } = props

  const classes = useStyles()
  const theme = useTheme()

  if (value === undefined || value === null) return null

  if (to) {
    return <DiscoLink to={to}>{renderContent()}</DiscoLink>
  }

  if (onClick) {
    return (
      <DiscoContainerButton onClick={onClick}>{renderContent()}</DiscoContainerButton>
    )
  }

  return renderContent()

  function renderContent() {
    return (
      <Paper
        elevation={0}
        className={classnames(classes.paper, propClasses?.root)}
        data-testid={testid}
      >
        <div className={classes.headerContainer}>
          <div className={classes.textTagContainer}>
            <DiscoText
              variant={"heading-xxl"}
              className={classes.value}
              testid={`${testid}.value`}
              color={
                theme.palette.type === "dark" ? "groovy.onDark.100" : "groovy.grey.700"
              }
            >
              {
                /** If value is already formatted, display as-is */
                typeof value === "string"
                  ? value
                  : /** Display large number in compact format. ie, 1.5k, 2.2M */
                    new Intl.NumberFormat("en", { notation: "compact" }).format(
                      Number(value)
                    ) || "--"
              }
            </DiscoText>

            {tags && (
              <div className={classes.tags} data-testid={`${testid}.tags`}>
                {tags}
              </div>
            )}
          </div>

          {icons && (
            <div data-testid={`${testid}.icons`} className={classes.icons}>
              {icons}
            </div>
          )}
        </div>
        <div className={classes.subtitleContainer}>
          <DiscoText noWrap testid={`${testid}.subtitle`} color={"text.secondary"}>
            {subtitle}
          </DiscoText>
          {tooltipText && (
            <DiscoTooltip
              content={tooltipText}
              buttonProps={{ classes: { root: classes.iconButton } }}
            />
          )}
        </div>
      </Paper>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  paper: {
    minWidth: 0,
    padding: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.big,
    border:
      theme.palette.type === "dark"
        ? theme.palette.constants.borderSmall
        : `1px solid #E4E4E7`,
    height: "100%",
    boxShadow: theme.palette.groovyDepths.xs,

    "& $icons": {
      opacity: 0,
    },

    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },

    "&:hover $icons": {
      opacity: 1,
    },
  },
  paperSkeleton: {
    minWidth: 0,
    padding: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.big,
    border:
      theme.palette.type === "dark"
        ? theme.palette.constants.borderSmall
        : `1px solid #E4E4E7`,
    height: "100%",
    boxShadow: theme.palette.groovyDepths.xs,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),
  },
  value: {
    fontWeight: 400,
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
  },
  icons: {
    alignSelf: "flex-start",
  },
  tags: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
  },
  textTagContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default ProductAdminStatisticsReportCard

export function ProductAdminStatisticsReportCardSkeleton() {
  const classes = useSkeletonStyles()

  return (
    <Paper elevation={0} className={classes.paperSkeleton}>
      <div className={classes.headerContainer}>
        <DiscoTextSkeleton
          className={classes.value}
          width={"60%"}
          variant={"heading-xxl"}
        />
      </div>
      <div className={classes.subtitleContainer}>
        <DiscoTextSkeleton width={"40%"} />
      </div>
    </Paper>
  )
}

const useSkeletonStyles = useStyles
