import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import EventsDashboardBlockCardView from "@/dashboard/blocks/kinds/EventsDashboardBlockCardView"
import EventsDashboardBlockListView from "@/dashboard/blocks/kinds/EventsDashboardBlockListView"
import { EventsDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockFragment.graphql"
import { graphql, useFragment } from "react-relay"

interface Props {
  dashboardBlockKey: EventsDashboardBlockFragment$key
  index?: number
}

function EventsDashboardBlock({ dashboardBlockKey, index }: Props) {
  const activeProduct = useActiveProduct()
  const { connectedApps } = useConnectedProductApps()

  const block = useFragment<EventsDashboardBlockFragment$key>(
    graphql`
      fragment EventsDashboardBlockFragment on UpcomingEventsDashboardBlock {
        eventsView: view
        ...EventsDashboardBlockCardViewFragment
        ...EventsDashboardBlockListViewFragment
      }
    `,
    dashboardBlockKey
  )

  // If no events app on experience dashboard, return null
  if (activeProduct && !connectedApps.has("events")) return null

  return block.eventsView === "card" ? (
    <EventsDashboardBlockCardView dashboardBlockKey={block} index={index} />
  ) : (
    <EventsDashboardBlockListView dashboardBlockKey={block} index={index} />
  )
}

export default EventsDashboardBlock
