/**
 * @generated SignedSource<<c5b4fedf799b3f17d01ae3bcda72a082>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardBlockDragDropProviderQuery$variables = {
  dashboardId: string;
};
export type DashboardBlockDragDropProviderQuery$data = {
  readonly dashboard: {
    readonly __typename: "Dashboard";
    readonly mainBlocks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly ordering: number;
        };
      }>;
    };
    readonly sideBlocks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly ordering: number;
        };
      }>;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type DashboardBlockDragDropProviderQuery = {
  variables: DashboardBlockDragDropProviderQuery$variables;
  response: DashboardBlockDragDropProviderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dashboardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dashboardId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "position",
    "value": "main"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordering",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DashboardBlockNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "position",
    "value": "side"
  }
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DashboardBlockNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardBlockDragDropProviderQuery",
    "selections": [
      {
        "alias": "dashboard",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "mainBlocks",
                "args": (v3/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "blocks(position:\"main\")"
              },
              {
                "alias": "sideBlocks",
                "args": (v7/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "blocks(position:\"side\")"
              }
            ],
            "type": "Dashboard",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardBlockDragDropProviderQuery",
    "selections": [
      {
        "alias": "dashboard",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "mainBlocks",
                "args": (v3/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": "blocks(position:\"main\")"
              },
              {
                "alias": "sideBlocks",
                "args": (v7/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": "blocks(position:\"side\")"
              }
            ],
            "type": "Dashboard",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cb33fa04d6d7d4f3483ff9569675241",
    "id": null,
    "metadata": {},
    "name": "DashboardBlockDragDropProviderQuery",
    "operationKind": "query",
    "text": "query DashboardBlockDragDropProviderQuery(\n  $dashboardId: ID!\n) {\n  dashboard: node(id: $dashboardId) {\n    __typename\n    ... on Dashboard {\n      mainBlocks: blocks(position: main) {\n        edges {\n          node {\n            __typename\n            id\n            ordering\n          }\n        }\n      }\n      sideBlocks: blocks(position: side) {\n        edges {\n          node {\n            __typename\n            id\n            ordering\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ba3610075e2a8671a9991d4bde7b141";

export default node;
