import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { EventsDashboardBlockListItem$key } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockListItem.graphql"
import OccurrenceNameAndTime from "@/product/course/event/components/OccurrenceNameAndTime"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DaySquare from "@components/date/day-square/DaySquare"
import { DiscoChip, DiscoLink, DiscoTextSkeleton } from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { setSearchParams } from "@utils/url/urlUtils"
import { graphql, useFragment } from "react-relay"

interface EventsDashboardBlockListItemProps {
  occurrenceKey: EventsDashboardBlockListItem$key
  hideDate?: boolean
}

function EventsDashboardBlockListItem({
  occurrenceKey,
  hideDate,
}: EventsDashboardBlockListItemProps) {
  const classes = useStyles()
  const occurrence = useFragment<EventsDashboardBlockListItem$key>(
    graphql`
      fragment EventsDashboardBlockListItem on Occurrence {
        id
        datetimeRange
        status
        ...OccurrenceNameAndTimeFragment
      }
    `,
    occurrenceKey
  )

  return (
    <div key={occurrence.id} className={classes.event} data-testid={"event-header"}>
      <DiscoLink
        to={{
          search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
            drawerOccurrenceId: occurrence.id,
            drawerEventTab: "details",
          }),
        }}
        className={classes.eventLink}
      >
        <div className={classes.daySquareContainer}>
          <DaySquare
            testid={"list-event-day"}
            date={new Date(occurrence.datetimeRange[0])}
          />
        </div>
        <OccurrenceNameAndTime occurrenceKey={occurrence} hideDate={hideDate} />
        {occurrence.status === "draft" && <DiscoChip color={"yellow"} label={"Draft"} />}
      </DiscoLink>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  event: {
    marginTop: theme.spacing(2),
  },
  eventLink: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    minWidth: 0,
  },
  daySquareContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
}))

export function EventsDashboardBlockListItemSkeleton() {
  const theme = useTheme()
  const classes = useStyles({})
  return (
    <div className={classes.event}>
      <Grid container direction={"row"}>
        <Grid item xs={"auto"}>
          <Skeleton
            variant={"rect"}
            style={{ borderRadius: theme.measure.borderRadius.medium }}
            width={56}
            height={56}
          />
        </Grid>
        <Grid item container xs direction={"column"} className={classes.text}>
          <DiscoTextSkeleton width={"50%"} />
          <DiscoTextSkeleton width={"50%"} />
        </Grid>
      </Grid>
    </div>
  )
}

export default EventsDashboardBlockListItem
