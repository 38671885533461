import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { CommunityWelcomeHeroDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/CommunityWelcomeHeroDashboardBlockFragment.graphql"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import MembershipPlanSelectButton from "@/membership-plan/register/MembershipPlanSelectButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoButton, DiscoInput, DiscoText } from "@disco-ui"
import { useState } from "react"
import { useFragment } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: CommunityWelcomeHeroDashboardBlockFragment$key
  index?: number
}

export default function CommunityWelcomeHeroDashboardBlock({
  dashboardBlockKey,
  index,
}: Props) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const { authUser } = useAuthUser()

  const block = useFragment(
    graphql`
      fragment CommunityWelcomeHeroDashboardBlockFragment on CommunityWelcomeHeroDashboardBlock {
        title
        subtitle
        cover
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
        organization {
          plans: products(type: "membership_plan", hideDrafts: true) {
            totalCount
          }
        }
      }
    `,
    dashboardBlockKey
  )

  const isPublic = activeOrganization.visibility === "public"
  const plansCount = block.organization.plans.totalCount
  const { canEdit } = useDashboardContext()!

  // login redirect breaks when editing as admin, so disable the link
  const loginPath = canEdit ? undefined : generatePath(ROUTE_NAMES.AUTHENTICATION.LOGIN)

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div
        className={classes.container}
        data-testid={"CommunityWelcomeHeroDashboardBlock.container"}
      >
        <CoverPhoto
          coverPhoto={block.cover || activeOrganization.cover}
          testid={"CommunityWelcomeHeroDashboardBlock.coverPhoto"}
          customClassName={classes.cover}
        />
        <DiscoText
          variant={"heading-xl"}
          marginTop={7}
          testid={"CommunityWelcomeHeroDashboardBlock.title"}
          align={"center"}
        >
          {block.title}
        </DiscoText>
        <DiscoText
          color={"text.secondary"}
          marginTop={2}
          classes={{ root: classes.subtitle }}
          testid={"CommunityWelcomeHeroDashboardBlock.subtitle"}
          align={"center"}
        >
          {block.subtitle}
        </DiscoText>
        {(isPublic || !authUser || canEdit) && (
          <div className={classes.actionContainer}>
            {isPublic ? (
              <>
                <DiscoInput
                  placeholder={"Email address..."}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.emailInput}
                  data-testid={"CommunityWelcomeHeroDashboardBlock.email-input"}
                />
                {/* If there is only the free plan, do not need to show the plan selection */}
                {plansCount > 1 ? (
                  <MembershipPlanSelectButton>
                    {(buttonProps) => (
                      <DiscoButton
                        {...buttonProps}
                        testid={"CommunityWelcomeHeroDashboardBlock.join-button"}
                        to={{
                          search: `email=${encodeURIComponent(email)}`,
                        }}
                      >
                        {"Join Community"}
                      </DiscoButton>
                    )}
                  </MembershipPlanSelectButton>
                ) : (
                  <DiscoButton
                    to={`${generatePath(
                      ROUTE_NAMES.COMMUNITY.JOIN.ROOT
                    )}?email=${encodeURIComponent(email)}`}
                    testid={"CommunityWelcomeHeroDashboardBlock.join-button"}
                  >
                    {"Join Community"}
                  </DiscoButton>
                )}
              </>
            ) : (
              <DiscoButton
                to={loginPath}
                testid={"CommunityWelcomeHeroDashboardBlock.login-button"}
              >
                {"Sign In"}
              </DiscoButton>
            )}
          </div>
        )}

        <DashboardBlockAdminDropdown
          dashboardBlockKey={block}
          className={classes.overflow}
        />
      </div>
    </DashboardBlockItemTemplate>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cover: {
    width: "100%",
  },
  actionContainer: {
    margin: theme.spacing(3, 0),
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    maxWidth: "100%",
  },
  emailInput: {
    width: "360px",
    flexShrink: 1,
  },
  overflow: {
    position: "absolute",
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
  },
  subtitle: {
    whiteSpace: "pre-line",
  },
}))
