import { useActiveProduct } from "@/core/context/ActiveProductContext"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import ExperienceDetailsMembers from "@/dashboard/blocks/kinds/experience-details/ExperienceDetailsMembers"
import { ExperienceDetailsDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/ExperienceDetailsDashboardBlockFragment.graphql"
import useUserTimezone from "@/user/util/useUserTimezone"
import CalendarIcon from "@/core/ui/iconsax/linear/calendar.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Countdown from "@components/countdown/Countdown"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import ReadMoreButton from "@components/read-more-button/ReadMoreButton"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { isPast } from "date-fns"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: ExperienceDetailsDashboardBlockFragment$key
  index?: number
}

function ExperienceDetailsDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props

  const activeProduct = useActiveProduct()
  const timeZone = useUserTimezone()
  const classes = useStyles()
  const theme = useTheme()

  const block = useFragment<ExperienceDetailsDashboardBlockFragment$key>(
    graphql`
      fragment ExperienceDetailsDashboardBlockFragment on ExperienceDetailsDashboardBlock {
        id
        description
        showMembers
        showDates
        showCountdown
        showProgress
        showAttendance
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const [isNowStarted, setIsNowStarted] = useState(
    isPast(new Date(activeProduct!.startsAt!))
  )

  const startDateText = formatDateWithOptions({
    timeZone,
    format: DATE_FORMAT.DEFAULT_FULL_MONTH_WITHOUT_YEAR,
  })(new Date(activeProduct!.startDate!))

  const endDateText = formatDateWithOptions({
    timeZone,
    format: DATE_FORMAT.DEFAULT_FULL_MONTH,
  })(new Date(activeProduct!.endDate!))

  if (!activeProduct) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div className={classes.container}>
        {/* Cover */}
        <CoverPhoto coverPhoto={activeProduct.cover} customClassName={classes.cover} />
        <DashboardBlockAdminDropdown
          dashboardBlockKey={block}
          className={classes.dropdown}
        />

        <div className={classes.details}>
          {block.description && (
            // Limit to 5 lines of body-sm text + read more
            <ReadMoreButton textVariant={"body-sm"} maxContentHeight={5 * 22}>
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {block.description}
              </DiscoText>
            </ReadMoreButton>
          )}

          {/* Members */}
          {block.showMembers && <ExperienceDetailsMembers />}

          {/* Dates */}
          {block.showDates && activeProduct.startDate && activeProduct.endDate && (
            <div className={classes.dates}>
              <CalendarIcon color={theme.palette.text.disabled} width={24} />
              <DiscoText variant={"body-sm"} color={"text.secondary"} marginLeft={1}>
                {`${startDateText} - ${endDateText}`}
              </DiscoText>
            </div>
          )}

          {/* Countdown */}
          {block.showCountdown && !isNowStarted && activeProduct.startDate && (
            <div>
              <DiscoText
                variant={"body-xs-500"}
                className={classes.startingIn}
                color={"groovy.grey.400"}
                marginBottom={1}
              >
                {"Starts in..."}
              </DiscoText>
              <Countdown
                testid={"ExperienceDetailsDashboardBlock"}
                dateInUtc={new Date(activeProduct.startsAt!)}
                timerDelayInSeconds={1}
                className={classes.experienceCountdown}
                onEnd={() => setIsNowStarted(true)}
              />
            </div>
          )}
        </div>
      </div>
    </DashboardBlockItemTemplate>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  cover: {
    borderTopLeftRadius: theme.measure.borderRadius.big,
    borderTopRightRadius: theme.measure.borderRadius.big,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundSize: "cover",
    paddingTop: "50%",
  },
  dropdown: {
    position: "absolute",
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
  },
  details: {
    padding: theme.spacing(2.5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  dates: {
    display: "flex",
    alignItems: "center",
  },
  startingIn: {
    justifyContent: "center",
    fontWeight: 400,
    display: "flex",
  },
  experienceCountdown: {
    gridTemplateColumns: "repeat(auto-fit, 29%)",
    padding: 0,

    "& li": {
      width: "82px",
      height: "82px",

      "& h2": {
        fontSize: "24px",
        lineHeight: "20px",
      },
      "& p": {
        fontSize: "12px",
        textTransform: "uppercase",
        color: theme.palette.groovy.grey[400],
      },
    },
  },
}))

export default ExperienceDetailsDashboardBlock
