import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import { ExperienceInfoToolbarFragment$key } from "@/dashboard/blocks/kinds/__generated__/ExperienceInfoToolbarFragment.graphql"
import ProductTypeTag from "@/product/common/type-tag/ProductTypeTag"
import ProductUtils from "@/product/util/productUtils"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import ProductCountdownString from "@components/countdown/ProductCountdownString"
import { DiscoIcon, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoToolbar from "@disco-ui/toolbar/DiscoToolbar"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { range } from "@utils/array/arrayUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import classNames from "classnames"
import { isPast } from "date-fns"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: ExperienceInfoToolbarFragment$key
  className?: string
}

function ExperienceInfoToolbar(props: Props) {
  const { dashboardBlockKey, className } = props
  const classes = useStyles()
  const theme = useTheme()
  const timeZone = useUserTimezone()
  const activeProduct = useActiveProduct()!
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"))
  const showOnHoverClasses = useShowOnHoverStyles()
  const membersLabel = useLabel("organization_member")

  const block = useFragment<ExperienceInfoToolbarFragment$key>(
    graphql`
      fragment ExperienceInfoToolbarFragment on BannerDashboardBlock {
        id
        showDates
        showCountdown
        showCover
        showAvailability
        cover
        product {
          ...ProductTypeTagFragment
        }
        ...DashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  const [isNowStarted, setIsNowStarted] = useState(
    isPast(new Date(activeProduct.startsAt!))
  )
  const hasEnded = activeProduct.endsAt && isPast(new Date(activeProduct.endsAt))

  const startDateText = formatDateWithOptions({
    timeZone,
    format: isMdScreen
      ? DATE_FORMAT.DEFAULT_MONTH_AND_DAY
      : DATE_FORMAT.DEFAULT_FULL_MONTH_WITHOUT_YEAR,
  })(new Date(activeProduct.startsAt!))

  const endDateText = formatDateWithOptions({
    timeZone,
    format: isMdScreen
      ? DATE_FORMAT.DEFAULT_MONTH_AND_DAY
      : DATE_FORMAT.DEFAULT_FULL_MONTH,
  })(new Date(activeProduct.endsAt!))

  if (!block) return null

  return (
    <DiscoToolbar
      className={classNames(classes.toolbar, showOnHoverClasses.hoverable, className)}
      leftOptions={
        <div className={classes.leftOptions}>
          {/* Type Tag */}
          {block.product && (
            <div className={classes.option}>
              <DiscoIcon
                icon={"label"}
                color={theme.palette.text.disabled}
                width={20}
                height={20}
              />
              <ProductTypeTag productKey={block.product} />
            </div>
          )}

          {/* Dates */}
          {block.showDates && activeProduct.startsAt && activeProduct.endsAt && (
            <div className={classes.option}>
              <DiscoIcon
                icon={"calendar"}
                color={theme.palette.text.disabled}
                width={20}
                height={20}
              />
              <DiscoText
                variant={"body-sm"}
                color={"text.secondary"}
                display={"inline"}
              >{`${startDateText} - ${endDateText}`}</DiscoText>
            </div>
          )}

          {/* Countdown */}
          {block.showCountdown && activeProduct.startsAt && (
            <div className={classes.option}>
              <DiscoIcon
                icon={"time"}
                color={theme.palette.text.disabled}
                width={20}
                height={20}
              />
              <DiscoText
                variant={"body-sm"}
                color={"text.secondary"}
                display={"inline"}
                className={classes.countdown}
              >
                {hasEnded ? (
                  "Ended"
                ) : isNowStarted ? (
                  "Live Now"
                ) : (
                  <ProductCountdownString
                    onEnd={onIsLive}
                    startsAt={new Date(activeProduct.startsAt!)}
                    prefixText={isMdScreen ? "In" : "Starts in"}
                    textProps={{ variant: "body-sm", color: "text.secondary" }}
                  />
                )}
              </DiscoText>
            </div>
          )}

          {/* Status */}
          {block.showAvailability && (
            <div className={classes.option}>
              <DiscoIcon
                icon={ProductUtils.getAvailabilityIcon(
                  activeProduct.registrationAvailability
                )}
                color={theme.palette.text.disabled}
                width={20}
                height={20}
              />
              <DiscoText variant={"body-sm"} color={"text.secondary"} display={"inline"}>
                {ProductUtils.getAvailabilityLabel(
                  activeProduct.registrationAvailability,
                  membersLabel
                )}
              </DiscoText>
            </div>
          )}
        </div>
      }
      rightOptions={
        <div className={classes.rightOptions}>
          {/* More Actions */}
          {!block.showCover && (
            <DashboardBlockAdminDropdown
              dashboardBlockKey={block}
              className={classNames(showOnHoverClasses.showable)}
            />
          )}
        </div>
      }
    />
  )

  function onIsLive() {
    setIsNowStarted(true)
  }
}

const useStyles = makeUseStyles((theme) => ({
  toolbar: {
    [theme.breakpoints.down("xs")]: {
      height: "fit-content",
      alignItems: "flex-start",
    },
  },
  leftOptions: {
    gap: theme.spacing(2.5),
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1.5),
      width: "100%",
    },
  },
  rightOptions: {
    gap: theme.spacing(2.5),
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(0.5),

    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  option: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  countdown: {
    "& div": {
      ...theme.typography["body-sm"],
      color: theme.palette.text.secondary,
    },
  },
}))

type SkeletonProps = Omit<Props, "dashboardBlockKey">

export const ExperienceInfoToolbarSkeleton: React.FC<SkeletonProps> = ({
  className,
}: SkeletonProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const activeProduct = useActiveProduct()!
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"))
  const canManageCurriculum = activeProduct.viewerPermissions.has("curriculum.manage")

  return (
    <DiscoToolbar
      className={classNames(classes.toolbar, className)}
      leftOptions={
        <div className={classes.leftOptions}>
          {range(4).map((i) => (
            <div key={i} className={classes.option}>
              <Skeleton variant={"circle"} width={20} height={20} />
              <DiscoTextSkeleton width={isMdScreen ? "70px" : "125px"} />
            </div>
          ))}
        </div>
      }
      rightOptions={
        <div className={classes.rightOptions}>
          {canManageCurriculum && <Skeleton variant={"circle"} width={20} height={20} />}
        </div>
      }
    />
  )
}

export default Relay.withSkeleton<Props>({
  component: ExperienceInfoToolbar,
  skeleton: ExperienceInfoToolbarSkeleton,
})
