/**
 * @generated SignedSource<<188a635881ffb09320402970af1d531c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
export type ReorderDashboardBlocksInput = {
  dashboardId: string;
  mainBlockIds: ReadonlyArray<string>;
  sideBlockIds: ReadonlyArray<string>;
};
export type DashboardBlockDragDropProviderMutation$variables = {
  input: ReorderDashboardBlocksInput;
};
export type DashboardBlockDragDropProviderMutation$data = {
  readonly response: {
    readonly node: {
      readonly mainBlocks: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly ordering: number;
            readonly position: DashboardBlockPosition;
          };
        }>;
      };
      readonly sideBlocks: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly ordering: number;
            readonly position: DashboardBlockPosition;
          };
        }>;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type DashboardBlockDragDropProviderMutation = {
  variables: DashboardBlockDragDropProviderMutation$variables;
  response: DashboardBlockDragDropProviderMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "position",
    "value": "main"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordering",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DashboardBlockNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "position",
    "value": "side"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DashboardBlockNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardBlockDragDropProviderMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ReorderDashboardBlocksResponse",
        "kind": "LinkedField",
        "name": "reorderDashboardBlocks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": "mainBlocks",
                "args": (v2/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "blocks(position:\"main\")"
              },
              {
                "alias": "sideBlocks",
                "args": (v7/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "blocks(position:\"side\")"
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardBlockDragDropProviderMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "ReorderDashboardBlocksResponse",
        "kind": "LinkedField",
        "name": "reorderDashboardBlocks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": "mainBlocks",
                "args": (v2/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": "blocks(position:\"main\")"
              },
              {
                "alias": "sideBlocks",
                "args": (v7/*: any*/),
                "concreteType": "DashboardBlockNodeConnection",
                "kind": "LinkedField",
                "name": "blocks",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": "blocks(position:\"side\")"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf841a3841ef9410db083df357d315f8",
    "id": null,
    "metadata": {},
    "name": "DashboardBlockDragDropProviderMutation",
    "operationKind": "mutation",
    "text": "mutation DashboardBlockDragDropProviderMutation(\n  $input: ReorderDashboardBlocksInput!\n) {\n  response: reorderDashboardBlocks(input: $input) {\n    node {\n      mainBlocks: blocks(position: main) {\n        edges {\n          node {\n            __typename\n            id\n            ordering\n            position\n          }\n        }\n      }\n      sideBlocks: blocks(position: side) {\n        edges {\n          node {\n            __typename\n            id\n            ordering\n            position\n          }\n        }\n      }\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "800d0db0e6a45e26f2d230a60af55690";

export default node;
