import ContentUsageGridItem, {
  ContentUsageGridItemSkeleton,
} from "@/content-usage/modules/grid/ContentUsageGridItem"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import { CollectionFolderDashboardBlockContentFragment$key } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContentFragment.graphql"
import { CollectionFolderDashboardBlockContentPaginationQuery } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContentPaginationQuery.graphql"
import { CollectionFolderDashboardBlockContentQuery } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContentQuery.graphql"
import { CollectionFolderDashboardBlockContent_PaginationFragment$key } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContent_PaginationFragment.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoCarousel,
  DiscoCarouselSkeleton,
  DiscoEmptyState,
  DiscoLink,
  DiscoText,
} from "@disco-ui"
import {
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

interface CollectionFolderDashboardBlockContentProps {
  dashboardBlockKey: CollectionFolderDashboardBlockContentFragment$key
  className?: string
}

function CollectionFolderDashboardBlockContent({
  dashboardBlockKey,
  className,
}: CollectionFolderDashboardBlockContentProps) {
  const classes = useStyles()

  const block = useFragment<CollectionFolderDashboardBlockContentFragment$key>(
    graphql`
      fragment CollectionFolderDashboardBlockContentFragment on CollectionFolderDashboardBlock {
        contentId
        position
        ...DashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  const { sliceSize, ...carouselProps } = useDashboardBlockCarouselSize(3, block)

  const { content } = useLazyLoadQuery<CollectionFolderDashboardBlockContentQuery>(
    graphql`
      query CollectionFolderDashboardBlockContentQuery($id: ID!, $first: Int) {
        content: node(id: $id) {
          ... on Content {
            name
            usages {
              edges {
                node {
                  id
                  collection {
                    id
                    product {
                      slug
                    }
                    app {
                      customAppTitle
                    }
                  }
                }
              }
            }
            ...CollectionFolderDashboardBlockContent_PaginationFragment
              @arguments(first: $first)
          }
        }
      }
    `,
    { id: block.contentId, first: sliceSize }
  )

  const {
    data,
    hasPrevious,
    loadNext,
    hasNext,
    isLoadingNext: contentIsLoadingNext,
    refetch,
  } = usePaginationFragment<
    CollectionFolderDashboardBlockContentPaginationQuery,
    CollectionFolderDashboardBlockContent_PaginationFragment$key
  >(
    graphql`
      fragment CollectionFolderDashboardBlockContent_PaginationFragment on Content
      @refetchable(queryName: "CollectionFolderDashboardBlockContentPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        children(first: $first, after: $after)
          @connection(key: "CollectionFolderDashboardBlockContent_children") {
          __id
          totalCount
          edges {
            node {
              id
              content {
                name
              }
              ...ContentUsageGridItemFragment
            }
          }
        }
      }
    `,
    content
  )

  // Listen for invalidation when adding content item
  useSubscribeToInvalidationState(data?.children ? [data?.children?.__id] : [], () => {
    refetch({
      first: sliceSize,
    })
  })

  const contentUsages = Relay.connectionToArray(data?.children)
  const totalCount = data?.children?.totalCount || 0

  const usage = Relay.connectionToArray(content?.usages)[0]
  if (!usage?.collection?.app) return null
  const collectionName = usage.collection.app.customAppTitle

  const path = usage.collection.product
    ? generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.DETAIL, {
        productSlug: usage.collection.product.slug,
        collectionId: usage.collection.id!,
      })
    : generatePath(ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL, {
        collectionId: usage.collection.id!,
      })

  return (
    <DiscoCarousel
      title={content?.name}
      subTitle={
        <DiscoText variant={"body-xs"} component={"span"} noWrap>
          {"in "}

          <DiscoLink to={{ pathname: path }}>
            <DiscoText variant={"body-xs"} color={"primary.main"} component={"span"}>
              {collectionName}
            </DiscoText>
          </DiscoLink>
        </DiscoText>
      }
      moreActions={
        <DashboardBlockAdminDropdown dashboardBlockKey={block} className={className} />
      }
      data={contentUsages}
      item={(contentUsage, i) => (
        <ContentUsageGridItem
          key={contentUsage.id}
          testid={`CollectionFolderDashboardBlock.item-${i}`}
          contentUsageKey={contentUsage}
          showOverflow={false}
          className={classes.item}
        />
      )}
      testid={"CollectionFolderDashboardBlock"}
      refetch={{
        hasNext,
        hasPrevious,
        loadMore: () => {
          loadNext(sliceSize)
        },
        isLoading: contentIsLoadingNext,
      }}
      itemSkeleton={<ContentUsageGridItemSkeleton />}
      totalCount={totalCount}
      isDashboardBlock
      emptyState={
        <DiscoEmptyState
          className={classes.emptyState}
          testid={"CollectionFolderDashboardBlockContent"}
          title={"No Items"}
          variant={"compact"}
        />
      }
      {...carouselProps}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    width: "unset",
  },
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))

export function CollectionFolderDashboardBlockContentSkeleton() {
  return (
    <DiscoCarouselSkeleton
      showTitle
      item={<ContentUsageGridItemSkeleton />}
      slidesPerView={3}
    />
  )
}

export default Relay.withSkeleton({
  component: CollectionFolderDashboardBlockContent,
  skeleton: CollectionFolderDashboardBlockContentSkeleton,
})
