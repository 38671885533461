import Badge from "@/admin/experiences/badges/Badge"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useStreamChannel } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { ChannelsDashboardBlockItemFragment$key } from "@/dashboard/blocks/kinds/__generated__/ChannelsDashboardBlockItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useStreamChannelsTotalUnreadCount from "@components/chat/channel/util/useStreamChannelsTotalUnreadCount"
import CountBadge from "@components/square-count-badge/CountBadge"
import { DiscoSection, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { DiscoCarouselItem } from "@disco-ui/carousel/DiscoCarouselItem"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { graphql, useFragment } from "react-relay"
import { generatePath } from "react-router-dom"

interface ChannelsDashboardBlockItemProps extends TestIDProps {
  channelKey: ChannelsDashboardBlockItemFragment$key
}

function ChannelsDashboardBlockItem({
  channelKey,
  testid = "ChannelsDashboardBlockItem",
}: ChannelsDashboardBlockItemProps) {
  const classes = useStyles()
  const activeProduct = useActiveProduct()!
  const channel = useFragment<ChannelsDashboardBlockItemFragment$key>(
    graphql`
      fragment ChannelsDashboardBlockItemFragment on ChatChannel {
        id
        externalChannelId
        visibility
        productApp {
          customAppTitle
          visibility
          ordering
          badge {
            ...BadgeFragment
          }
        }
      }
    `,
    channelKey
  )

  const streamChannel = useStreamChannel(channel.externalChannelId)
  const numUnreads = useStreamChannelsTotalUnreadCount(
    streamChannel ? [streamChannel] : []
  )

  if (!channel) return null
  if (!channel.productApp) return null
  const { badge, customAppTitle } = channel.productApp

  const channelPath = generatePath(ROUTE_NAMES.PRODUCT.CHAT.CHANNEL, {
    channelId: channel.id,
    productSlug: activeProduct.slug,
  })

  return (
    <DiscoCarouselItem
      to={channelPath}
      testid={`${testid}.${customAppTitle}`}
      className={classes.channel}
    >
      <div className={classes.badgeAndTitle}>
        <div className={classes.icon}>{<Badge badgeKey={badge} />}</div>
        <DiscoText variant={"body-md-600"} truncateText={1}>
          {customAppTitle}
        </DiscoText>
      </div>

      <div className={classes.unreads}>
        {Boolean(numUnreads) && <CountBadge count={numUnreads} />}
        <DiscoText
          variant={"body-xs-500"}
          color={"groovy.grey.400"}
          className={classes.caption}
        >
          {numUnreads ? pluralize("unread message", numUnreads) : "No unread messages"}
        </DiscoText>
      </div>
    </DiscoCarouselItem>
  )
}

const useStyles = makeUseStyles((theme) => ({
  channel: {
    minHeight: "90px",
  },
  badgeAndTitle: {
    display: "flex",
    marginBottom: theme.spacing(1),
    gap: theme.spacing(0.5),
    width: "100%",
  },
  unreads: {
    display: "flex",
    gap: theme.spacing(1),
  },
  caption: {
    fontWeight: 400,
  },
  channelLink: {
    width: "100%",
    alignItems: "center",
    minWidth: 0,
  },
  icon: {
    width: "20px",
    alignSelf: "center",
    display: "flex",
  },
}))

export function ChannelsDashboardBlockItemSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.channelLink}>
      <DiscoSection className={classes.channel}>
        <div className={classes.badgeAndTitle}>
          <Skeleton width={"24px"} />
          <DiscoTextSkeleton variant={"body-md-600"} width={"80%"} />
        </div>

        <div className={classes.unreads}>
          <DiscoTextSkeleton
            variant={"body-xs-500"}
            className={classes.caption}
            width={"60%"}
          />
        </div>
      </DiscoSection>
    </div>
  )
}

export default Relay.withSkeleton({
  component: ChannelsDashboardBlockItem,
  skeleton: ChannelsDashboardBlockItemSkeleton,
})
