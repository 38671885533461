import { useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { MembersListDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/MembersListDashboardBlockFragment.graphql"
import { MembersListDashboardBlockQuery } from "@/dashboard/blocks/kinds/__generated__/MembersListDashboardBlockQuery.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { range } from "lodash"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router-dom"

interface Props {
  dashboardBlockKey: MembersListDashboardBlockFragment$key
  index?: number
}

function MembersListDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props

  const block = useFragment<MembersListDashboardBlockFragment$key>(
    graphql`
      fragment MembersListDashboardBlockFragment on MembersListDashboardBlock {
        organizationId
        product {
          id
          slug
        }
        memberCount: count
        memberType
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const classes = useStyles()

  const labels = useLabels()
  const memberLabel = block.product
    ? labels.product_member.plural
    : labels.organization_member.plural
  const adminLabel = block.product ? labels.product_admin.plural : "Admins"

  const { organization, product } = useLazyLoadQuery<MembersListDashboardBlockQuery>(
    graphql`
      query MembersListDashboardBlockQuery(
        $organizationId: ID!
        $productId: ID!
        $count: Int!
        $organizationRoles: [OrganizationRole!]
        $productRoles: [ProductRole!]
      ) {
        organization: node(id: $organizationId) {
          ... on Organization {
            organizationMemberships(
              first: $count
              orderBy: members_list
              roles: $organizationRoles
            ) {
              edges {
                node {
                  id
                  member {
                    id
                    bio
                    fullName
                    ...ProfileAvatarWithDetailsFragment
                  }
                }
              }
            }
          }
        }
        product: node(id: $productId) {
          ... on Product {
            productMemberships(
              first: $count
              orderBy: { field: "last_activity_at", direction: DESC }
              roles: $productRoles
            ) {
              edges {
                node {
                  id
                  member {
                    id
                    bio
                    fullName
                    ...ProfileAvatarWithDetailsFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      organizationId: block.product ? "" : block.organizationId,
      productId: block.product?.id || "",
      count: block.memberCount,
      organizationRoles:
        block.memberType === "admin_only"
          ? ["owner", "admin"]
          : block.memberType === "member_only"
          ? ["member"]
          : undefined,
      productRoles:
        block.memberType === "admin_only"
          ? ["manager", "instructor"]
          : block.memberType === "member_only"
          ? ["member"]
          : undefined,
    }
  )

  const memberships = Relay.connectionToArray(
    block.product ? product?.productMemberships : organization?.organizationMemberships
  )

  const permissions = useActiveProductOrOrganizationPermissions()

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div className={classes.container}>
        <div className={classes.header}>
          <DiscoText variant={"body-lg-600"}>
            {block.memberType === "admin_only" ? adminLabel : memberLabel}
          </DiscoText>

          <DashboardBlockAdminDropdown dashboardBlockKey={block} />
        </div>

        <div className={classes.membersListContainer}>
          {memberships.map(({ member }) => (
            <div key={member.id}>
              <ProfileAvatarWithDetails
                userKey={member}
                testid={`MembersListDashboardBlock.list.${member.fullName}`}
                details={member.bio}
              />
            </div>
          ))}
        </div>

        {permissions.has("members.read") && (
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            width={"100%"}
            className={classes.viewAllButton}
            to={
              block.product
                ? generatePath(ROUTE_NAMES.PRODUCT.MEMBERS.LIST, {
                    productSlug: block.product.slug,
                  })
                : ROUTE_NAMES.COMMUNITY.MEMBERS.LIST
            }
          >
            {"View All"}
          </DiscoButton>
        )}
      </div>
    </DashboardBlockItemTemplate>
  )
}

export function MembersListDashboardBlockSkeleton() {
  const classes = useStyles({})

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <DiscoTextSkeleton variant={"heading-sm"} width={"50%"} />
      </div>

      <div className={classes.membersListContainer}>
        {range(4).map((i) => (
          <Skeleton key={i} width={"100%"} />
        ))}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2),
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  membersListContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  viewAllButton: {
    marginTop: theme.spacing(2),
  },
}))

export default Relay.withSkeleton({
  component: MembersListDashboardBlock,
  skeleton: MembersListDashboardBlockSkeleton,
})
