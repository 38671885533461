import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { DashboardBlockKind } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"
import { AddDashboardBlockFormState } from "@/dashboard/add/AddDashboardBlockModal"
import {
  DashboardBlockPosition,
  EditDashboardBlockFormFragment$data,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import {
  EditDashboardBlockFormMutation,
  EditDashboardBlockInput,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import BannerDashboardBlockForm from "@/dashboard/form/BannerDashboardBlockForm"
import ChannelsDashboardBlockForm from "@/dashboard/form/ChannelsDashboardBlockForm"
import CollectionFolderDashboardBlockForm from "@/dashboard/form/CollectionFolderDashboardBlockForm"
import CommunityWelcomeHeroDashboardBlockForm from "@/dashboard/form/CommunityWelcomeHeroDashboardBlockForm"
import ContentDashboardBlockForm from "@/dashboard/form/ContentDashboardBlockForm"
import CurriculumDashboardBlockForm from "@/dashboard/form/CurriculumDashboardBlockForm"
import EventsDashboardBlockForm from "@/dashboard/form/EventsDashboardBlockForm"
import ExperienceDetailsDashboardBlockForm from "@/dashboard/form/ExperienceDetailsDashboardBlockForm"
import FeaturedItemsDashboardBlockForm from "@/dashboard/form/FeaturedItemsDashboardBlockForm"
import FeedDashboardBlockForm from "@/dashboard/form/FeedDashboardBlockForm"
import LeaderboardDashboardBlockForm from "@/dashboard/form/LeaderboardDashboardBlockForm"
import MembersListDashboardBlockForm from "@/dashboard/form/MembersListDashboardBlockForm"
import RichTextDashboardBlockForm from "@/dashboard/form/RichTextDashboardBlockForm"
import WelcomeBannerDashboardBlockForm from "@/dashboard/form/WelcomeBannerDashboardBlockForm"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"

export type DashboardBlockFormStore =
  | FormStore<AddDashboardBlockFormState>
  | FormStore<
      EditDashboardBlockInput & Partial<EditDashboardBlockFormFragment$data>,
      EditDashboardBlockFormMutation
    >

interface BlockFormConfig {
  form: React.FC<{ form: DashboardBlockFormStore; onClose: () => void }>
  name: string
  description: string
}

export function useDashboardBlockKindForms(
  position?: DashboardBlockPosition
): Partial<Record<Exclude<DashboardBlockKind, "%future added value">, BlockFormConfig>> {
  const labels = useLabels()
  const activeProduct = useActiveProduct()
  const dashboard = useDashboardContext()!
  const singleColumnExperienceDashboard =
    activeProduct?.dashboard?.layout === "one_column"

  return {
    ...(!activeProduct &&
      !dashboard.isCommunityWelcome && {
        welcome_banner: {
          form: WelcomeBannerDashboardBlockForm,
          name: "Community Details",
          description: `Welcome ${labels.admin_member.plural} to your community`,
        },
      }),
    ...(dashboard.isCommunityWelcome && {
      community_welcome_hero: {
        form: CommunityWelcomeHeroDashboardBlockForm,
        name: "Hero Banner",
        description: "Set a cover image and text to welcome to your community",
      },
    }),
    ...(!dashboard.isCommunityWelcome && {
      members_list: {
        form: MembersListDashboardBlockForm,
        name: labels.admin_member.plural,
        description: `Prompt social connections within your ${
          activeProduct ? labels.admin_experience.singular : "community"
        }`,
      },
    }),
    ...(singleColumnExperienceDashboard && {
      banner: {
        form: BannerDashboardBlockForm,
        name: "Banner",
        description: `Set a cover image and ${labels.admin_experience.singular} details`,
      },
    }),
    ...(position === "main" &&
      !dashboard.isCommunityWelcome && {
        feed: {
          form: FeedDashboardBlockForm,
          name: "Feed",
          description: `Highlight the latest posts from across your ${
            activeProduct ? labels.admin_experience.singular : "community"
          }`,
        },
      }),
    featured_items: {
      form: FeaturedItemsDashboardBlockForm,
      name: `Feature ${labels.admin_experience.plural}`,
      description: `Spotlight ${labels.admin_experience.plural} from your community`,
    },
    memberships: {
      form: FeaturedItemsDashboardBlockForm,
      name: "Feature Memberships",
      description: "Spotlight Memberships from your community",
    },
    ...(!dashboard.isCommunityWelcome && {
      leaderboard: {
        form: LeaderboardDashboardBlockForm,
        name: "Leaderboard",
        description: `Show a leaderboard based on ${labels.admin_member.plural} engagement score.`,
      },
    }),
    rich_text: {
      form: RichTextDashboardBlockForm,
      name: "Rich Text",
      description: "Customize your content with our rich text editor",
    },
    collection_folder: {
      form: CollectionFolderDashboardBlockForm,
      name: "Collection Folder",
      description: "Add a collection folder from your product or community",
    },
    ...(!dashboard.isCommunityWelcome && {
      content: {
        form: ContentDashboardBlockForm,
        name: "Content",
        description: "Embed existing content from your community",
      },
    }),
    // Only allow for two column dashboards
    ...(activeProduct &&
      !singleColumnExperienceDashboard && {
        experience_details: {
          form: ExperienceDetailsDashboardBlockForm,
          name: `${labels.admin_experience.singular} Details`,
          description: `Provide general details about the ${labels.admin_experience.singular}`,
        },
      }),
    ...(!dashboard.isCommunityWelcome && {
      upcoming_events: {
        form: EventsDashboardBlockForm,
        name: "Events",
        description: "Highlight the most recent and next upcoming events",
      },
    }),
    ...(activeProduct && {
      curriculum: {
        form: CurriculumDashboardBlockForm,
        name: labels.curriculum.singular,
        description: "Show incomplete and upcoming Modules",
      },
    }),
    ...(activeProduct &&
      position === "main" && {
        channels: {
          form: ChannelsDashboardBlockForm,
          name: "Channels",
          description: `Show discussions across the ${
            activeProduct ? labels.admin_experience.singular : "community"
          }`,
        },
      }),
  }
}
