import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import LeaderboardDashboardBlockCarouselItem from "@/dashboard/blocks/kinds/LeaderboardDashboardBlockCarouselItem"
import { LeaderboardDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/LeaderboardDashboardBlockFragment.graphql"
import { LeaderboardDashboardBlockQuery } from "@/dashboard/blocks/kinds/__generated__/LeaderboardDashboardBlockQuery.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import TrophyGradiantIcon from "@assets/disco/icons/gradient/gradient-black-trophy.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoCarousel,
  DiscoDivider,
  DiscoEmptyState,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
  GroovyTextColorKind,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { range } from "lodash"
import pluralize from "pluralize"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"

interface Props {
  dashboardBlockKey: LeaderboardDashboardBlockFragment$key
  index?: number
}

function LeaderboardDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props
  const classes = useStyles()
  const productLabel = useLabel("experience")
  const isMobile = useIsMobile()
  const theme = useTheme()
  const { authUser } = useAuthUser()

  const block = useFragment<LeaderboardDashboardBlockFragment$key>(
    graphql`
      fragment LeaderboardDashboardBlockFragment on LeaderboardDashboardBlock {
        id
        organizationId
        product {
          id
          slug
        }
        memberCount: count
        title
        blockDescription: description
        position
        lookbackWindow
        blockView: view
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const { organization, product } = useLazyLoadQuery<LeaderboardDashboardBlockQuery>(
    graphql`
      query LeaderboardDashboardBlockQuery(
        $organizationId: ID!
        $productId: ID!
        $blockId: ID!
      ) {
        organization: node(id: $organizationId) {
          ... on Organization {
            viewerLeaderboard(blockId: $blockId) {
              score
              position
              member {
                id
                fullName
                bio
                ...ProfileAvatarWithDetailsFragment
                ...LeaderboardDashboardBlockCarouselItemFragment
              }
            }
            leaderboard(blockId: $blockId) {
              edges {
                node {
                  id
                  score
                  member {
                    id
                    fullName
                    bio
                    ...ProfileAvatarWithDetailsFragment
                    ...LeaderboardDashboardBlockCarouselItemFragment
                  }
                }
              }
            }
          }
        }
        product: node(id: $productId) {
          ... on Product {
            viewerLeaderboard(blockId: $blockId) {
              score
              position
              member {
                id
                fullName
                bio
                ...ProfileAvatarWithDetailsFragment
                ...LeaderboardDashboardBlockCarouselItemFragment
              }
            }
            leaderboard(blockId: $blockId) {
              edges {
                node {
                  id
                  score
                  member {
                    id
                    fullName
                    bio
                    ...ProfileAvatarWithDetailsFragment
                    ...LeaderboardDashboardBlockCarouselItemFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      organizationId: block.product?.id ? "" : block.organizationId,
      productId: block.product?.id || "",
      blockId: block.id,
    }
  )

  const { ...carouselProps } = useDashboardBlockCarouselSize(3, block, "left")
  const memberships = Relay.connectionToArray(
    block.product ? product?.leaderboard : organization?.leaderboard
  )
  const viewerScore = block.product
    ? product?.viewerLeaderboard
    : organization?.viewerLeaderboard

  const membershipsWithViewer = viewerScore
    ? [...memberships, { ...viewerScore, id: viewerScore.member.id }]
    : undefined

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div className={classes.container}>
        <div className={classes.header}>
          <DiscoText variant={"body-lg-600"}>{block.title}</DiscoText>
          <div className={classes.rhsContainer}>
            <DiscoTooltip content={block.blockDescription} />
            <DashboardBlockAdminDropdown dashboardBlockKey={block} />
          </div>
        </div>

        {memberships.length ? (
          <>
            {block.blockView === "card" ? (
              <DiscoCarousel
                data={membershipsWithViewer || memberships}
                item={(m, i) => (
                  <LeaderboardDashboardBlockCarouselItem
                    key={m.id}
                    userKey={m.member}
                    testid={`LeaderboardDashboardBlockCarouselItem.${i}`}
                    badgeIcon={
                      i >= block.memberCount && viewerScore && viewerScore.position
                        ? getIconForIndex(viewerScore.position, 24)
                        : getIconForIndex(i, 24)
                    }
                    score={
                      membershipsWithViewer
                        ? membershipsWithViewer[i].score
                        : memberships[i].score
                    }
                  />
                )}
                totalCount={membershipsWithViewer?.length || memberships.length}
                slidesPerView={isMobile || block.position === "side" ? 1 : 3}
                className={classes.leaderboardCarousel}
                hideIndicator
                {...carouselProps}
              />
            ) : (
              <div className={classes.membersListContainer}>
                {memberships.map(({ member }, i) => (
                  <div key={member.id} className={classes.userRow}>
                    <ProfileAvatarWithDetails
                      userKey={member}
                      testid={`LeaderboardDashboardBlock.list.${member.fullName}`}
                      details={member.bio}
                      badgeIcon={getIconForIndex(i)}
                    />
                    <DiscoText
                      variant={"body-sm"}
                      color={"groovy.grey.400"}
                      className={classes.points}
                    >
                      {`${memberships[i].score} ${pluralize("pt", memberships[i].score)}`}
                    </DiscoText>
                  </div>
                ))}
                {viewerScore && viewerScore.position && authUser && (
                  <>
                    <DiscoDivider marginBottom={0} />

                    <div className={classes.userRow}>
                      <ProfileAvatarWithDetails
                        userKey={authUser}
                        details={authUser.bio}
                        badgeIcon={getIconForIndex(viewerScore.position)}
                      />
                      <DiscoText
                        variant={"body-sm"}
                        color={"groovy.grey.400"}
                        className={classes.points}
                      >
                        {`${viewerScore.score} ${pluralize("pt", viewerScore.score)}`}
                      </DiscoText>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <DiscoEmptyState
            className={classes.emptyState}
            testid={"LeaderboardDashboardBlock.emptyState"}
            icon={
              <TrophyGradiantIcon className={classes.trophyIcon} width={60} height={60} />
            }
            title={"Start Participating!"}
            subtitle={`Engage in this ${
              product ? productLabel.singular : "community"
            } to secure a spot on the leaderboard.`}
            variant={"compact"}
          />
        )}
      </div>
    </DashboardBlockItemTemplate>
  )

  function getIconForIndex(i: number, size = 20) {
    let background: string
    let textColor: GroovyTextColorKind = "common.white"
    // i+1 because we don't want zero-offset
    switch (i + 1) {
      case 1:
        background = theme.palette.gradient.gold
        break
      case 2:
        background = theme.palette.gradient.silver
        break
      case 3:
        background = theme.palette.gradient.bronze
        break
      default:
        background =
          theme.palette.type === "dark"
            ? theme.palette.groovy.onDark[500]
            : theme.palette.groovy.neutral[200]
        textColor = "groovy.neutral.700"
    }
    return (
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
          background,
        }}
        className={classes.badge}
      >
        <DiscoText color={textColor} variant={"body-xs"}>
          {i + 1}
        </DiscoText>
      </div>
    )
  }
}

export function LeaderboardDashboardBlockSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <DiscoTextSkeleton variant={"heading-sm"} width={"50%"} />
      </div>

      <div className={classes.membersListContainer}>
        {range(4).map((i) => (
          <Skeleton key={i} width={"100%"} />
        ))}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2),
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  membersListContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  rhsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  userRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  leaderboardCarousel: {
    paddingTop: 0,
  },
  badge: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    aspectRatio: "1/1",
  },
  points: {
    minWidth: "fit-content",
  },
  trophyIcon: {
    color: `${
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[200]
        : theme.palette.groovy.neutral[200]
    } !important`,
  },
}))

export default Relay.withSkeleton({
  component: LeaderboardDashboardBlock,
  skeleton: LeaderboardDashboardBlockSkeleton,
})
