import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import FeedDashboardBlockCardView from "@/dashboard/blocks/kinds/FeedDashboardBlockCardView"
import FeedDashboardBlockListView from "@/dashboard/blocks/kinds/FeedDashboardBlockListView"
import { FeedDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockFragment.graphql"
import { graphql, useFragment } from "react-relay"

interface Props {
  dashboardBlockKey: FeedDashboardBlockFragment$key
  index?: number
}

function FeedDashboardBlock({ dashboardBlockKey, index }: Props) {
  const activeProduct = useActiveProduct()
  const { connectedApps: connectedProductApps } = useConnectedProductApps()

  const block = useFragment<FeedDashboardBlockFragment$key>(
    graphql`
      fragment FeedDashboardBlockFragment on FeedDashboardBlock {
        feedView: view
        ...FeedDashboardBlockCardViewFragment
        ...FeedDashboardBlockListViewFragment
      }
    `,
    dashboardBlockKey
  )

  if (!block) return null

  // If the feed app is no longer connected on the product level, return null
  if (activeProduct && !connectedProductApps.has("posts")) return null

  return (
    <>
      {block.feedView === "card" ? (
        <FeedDashboardBlockCardView dashboardBlockKey={block} index={index} />
      ) : (
        <FeedDashboardBlockListView dashboardBlockKey={block} index={index} />
      )}
    </>
  )
}

export default FeedDashboardBlock
