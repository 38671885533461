import { ContentModuleListSkeleton } from "@/content-usage/modules/list/ContentModuleList"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import CurriculumDashboardBlockEmptyState from "@/dashboard/blocks/kinds/CurriculumDashboardBlockEmptyState"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { CurriculumDashboardBlockListViewFragment$key } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockListViewFragment.graphql"
import { CurriculumDashboardBlockListViewQuery } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockListViewQuery.graphql"
import CurriculumModuleList from "@/product/course/curriculum/section/list/CurriculumModuleList"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSection, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { DiscoMoreActionsDropdownSkeleton } from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import usePermissions from "@utils/hook/usePermissions"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  dashboardBlockKey: CurriculumDashboardBlockListViewFragment$key
  index?: number
}

function CurriculumDashboardBlockListView({ dashboardBlockKey, index }: Props) {
  const classes = useStyles()

  const block = useFragment<CurriculumDashboardBlockListViewFragment$key>(
    graphql`
      fragment CurriculumDashboardBlockListViewFragment on CurriculumDashboardBlock {
        productId
        title
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const [{ product }, refetch] =
    Relay.useRefetchableQuery<CurriculumDashboardBlockListViewQuery>(
      graphql`
        query CurriculumDashboardBlockListViewQuery($id: ID!) {
          product: node(id: $id) {
            ... on Product {
              curriculum {
                id
                modules {
                  totalCount
                }
                ...CurriculumModuleListFragment
              }
              ...usePermissionsFragment
            }
          }
        }
      `,
      { id: block.productId || "" },
      { fetchPolicy: "network-only" }
    )
  const canManage = usePermissions(product).has("curriculum.manage")

  if (!product?.curriculum) return null
  const { curriculum } = product
  if (!curriculum.modules.totalCount && !canManage) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoSection padding={2} border groovyDepths={"xs"}>
        <div className={classes.header}>
          <DiscoText variant={"body-lg-600"}>{block.title}</DiscoText>
          {canManage && <DashboardBlockAdminDropdown dashboardBlockKey={block} />}
        </div>
        {curriculum.modules.totalCount ? (
          <CurriculumModuleList
            curriculumKey={curriculum}
            showProgressIcon={!canManage}
            canReorder={false}
            alwaysStartCollapsed
          />
        ) : (
          <CurriculumDashboardBlockEmptyState
            curriculumId={curriculum.id}
            refetchCurriculum={refetchCurriculum}
          />
        )}
      </DiscoSection>
    </DashboardBlockItemTemplate>
  )

  function refetchCurriculum() {
    if (!block.productId) return
    refetch({ id: block.productId })
  }
}

function CurriculumDashboardBlockListViewSkeleton() {
  const classes = useStyles()
  return (
    <DiscoSection padding={2.5} border groovyDepths={"xs"}>
      <div className={classes.header}>
        <DiscoTextSkeleton variant={"body-lg-600"} width={"150px"} />
        <DiscoMoreActionsDropdownSkeleton />
      </div>
      <ContentModuleListSkeleton />
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2.5),
  },
}))

export default Relay.withSkeleton({
  component: CurriculumDashboardBlockListView,
  skeleton: CurriculumDashboardBlockListViewSkeleton,
})
