import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import {
  postWebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CurriculumDashboardBlockCardView from "@/dashboard/blocks/kinds/CurriculumDashboardBlockCardView"
import CurriculumDashboardBlockListView from "@/dashboard/blocks/kinds/CurriculumDashboardBlockListView"
import { CurriculumDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockFragment.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoSection, DiscoText } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { graphql, useFragment } from "react-relay"
import DashboardBlockItemTemplate from "./DashboardBlockItemTemplate"
import { DashboardBlockItemTemplateFragment$key } from "./__generated__/DashboardBlockItemTemplateFragment.graphql"

interface Props {
  dashboardBlockKey: CurriculumDashboardBlockFragment$key
  index?: number
}

export default function CurriculumDashboardBlock({ dashboardBlockKey, index }: Props) {
  const { embeditor } = useFeatureFlags()
  const isWebView = useIsWebView()
  const block = useFragment<CurriculumDashboardBlockFragment$key>(
    graphql`
      fragment CurriculumDashboardBlockFragment on CurriculumDashboardBlock {
        curriculumView: view
        ...DashboardBlockItemTemplateFragment
        ...CurriculumDashboardBlockCardViewFragment
        ...CurriculumDashboardBlockListViewFragment
      }
    `,
    dashboardBlockKey
  )
  const { connectedApps } = useConnectedProductApps()

  // Don't show the block if there's no longer a curriculum app
  if (!connectedApps.has("curriculum")) return null

  if (isWebView && embeditor) {
    return <CurriculumDashboardBlockWebView block={block} index={index} />
  }

  return block.curriculumView === "list" ? (
    <CurriculumDashboardBlockListView dashboardBlockKey={block} index={index} />
  ) : (
    <CurriculumDashboardBlockCardView dashboardBlockKey={block} index={index} />
  )
}

type WebViewBlockProps = Pick<Props, "index"> & {
  block: DashboardBlockItemTemplateFragment$key
}

function CurriculumDashboardBlockWebView({ block, index }: WebViewBlockProps) {
  const activeProduct = useActiveProduct()
  const classes = useStyles()

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoSection padding={2} border groovyDepths={"xs"}>
        <DiscoText variant={"body-lg-600"}>{"Curriculum"}</DiscoText>
        <div className={classes.content}>
          <DiscoButton onClick={redirectToCurriculum} variant={"outlined"} color={"grey"}>
            {"Go to Curriculum"}
          </DiscoButton>
        </div>
      </DiscoSection>
    </DashboardBlockItemTemplate>
  )

  function redirectToCurriculum() {
    if (!activeProduct) return

    postWebViewMessage({
      type: WebViewMessageType.mobile_redirect,
      frontendPath: ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT,
      params: {
        productSlug: activeProduct.slug,
      },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  content: {
    display: "flex",
    padding: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
  },
}))
